<template>
  <div style="overflow: hidden; overflow-y: scroll;">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-position="left"
      label-width="100px"
      class="demo-ruleForm"
    >
      <div class="flex bewteen wrap">
        <div class="width_50">
          <el-form-item label="企业名称" prop="value1" required label-width="150px">
            <el-input v-model="ruleForm.value1" placeholder='请输入'></el-input>
          </el-form-item>
        </div>
        <div class="width_50">
          <el-form-item label="统一社会信用代码" prop="value2" required label-width="150px">
            <el-input v-model="ruleForm.value2" placeholder='请输入'></el-input>
          </el-form-item>
        </div>
        <!-- <div class="width">
          <el-form-item label="机构码" prop="value3" required label-width="150px">
            <el-input v-model="ruleForm.value3" placeholder='请输入'></el-input>
          </el-form-item>
        </div> -->
        <div class="width_50">
          <el-form-item label="法定代表人" prop="value4" required label-width="150px">
            <el-input v-model="ruleForm.value4" placeholder='请输入'></el-input>
          </el-form-item>
        </div>
        <div class="width_50">
          <el-form-item label="平台负责人" prop="value5" required label-width="150px">
            <el-input v-model="ruleForm.value5" placeholder='请输入'></el-input>
          </el-form-item>
        </div>
        <div class="width_50">
          <el-form-item label="负责人联系电话" prop="value6" required label-width="150px">
            <el-input v-model="ruleForm.value6" placeholder='请输入'></el-input>
          </el-form-item>
        </div>
        <div class="width_50">
          <el-form-item label="电子邮箱" prop="value7" required label-width="150px">
            <el-input v-model="ruleForm.value7" placeholder='请输入'></el-input>
          </el-form-item>
        </div>
        <div class="width_50">
          <el-form-item label="联系人微信号" prop="value20" required label-width="150px">
            <el-input v-model="ruleForm.value20" placeholder='请输入'></el-input>
          </el-form-item>
        </div>
        <div class="width_50">
          <el-form-item label="注册日期" prop="value8" required label-width="150px">
            <el-date-picker type="date" placeholder="请输入" v-model="ruleForm.value8" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </div>

        <!-- <div class="width_50">
          <el-form-item label="机构性质" prop="value9" required label-width="150px">
            <el-select v-model="ruleForm.value9" placeholder="请选择">
                <el-option v-for='item in options1' :value='item.name' @click.native='getCateId(item, 1)'></el-option>
            </el-select>
          </el-form-item>
        </div>     -->    
        <div class="width_50">
          <el-form-item label="注册地址" prop="value10" required label-width="150px">
              <el-select v-model="ruleForm.value10" placeholder="请选择" clearable @clear='clearClass(1)'>
                  <el-option v-for="item in options" :value="item.name" @click.native='setAddressId(item.id, 1)'>
                  </el-option>
              </el-select>
          </el-form-item>
        </div>        
        <div class="width_50">
          <el-form-item label="通讯地址" prop="value11" required label-width="150px">
              <el-select v-model="ruleForm.value11" placeholder="请选择" clearable @clear='clearClass(2)'>
                  <el-option v-for="item in options" :value="item.name" @click.native='setAddressId(item.id, 2)'>
                  </el-option>
              </el-select>
          </el-form-item>
        </div>        
        
        <div class="width_50">
            <el-form-item label="注册详细地址" prop="value12" required label-width="150px">
                <el-input type="textarea" v-model="ruleForm.value12" placeholder='请与营业执照保持一致' :autosize="{ minRows: 3, maxRows: 99}"></el-input>
            </el-form-item>
        </div> 
        <div class="width_50">
            <el-form-item label="通讯详细地址" prop="value13" required label-width="150px">
                <el-input type="textarea" v-model="ruleForm.value13" placeholder='请与营业执照保持一致' :autosize="{ minRows: 3, maxRows: 99}"></el-input>
            </el-form-item>
        </div>  
        <div class="width_50">
          <el-form-item label="产品数量" prop="value14" label-width="150px">
            <el-input v-model="ruleForm.value14" :disabled="true" :placeholder='place_font'></el-input>
          </el-form-item>
        </div>
        <div class="width_50">
          <el-form-item label="机构性质" prop="value15" required label-width="150px">
            <el-select v-model="ruleForm.value15" placeholder="请选择" clearable>
                <el-option v-for='item in options2' :value='item.name' @click.native='getCateId(item, 2)'></el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- <div class="width_50">
          <el-form-item label="资金性质" label-width="150px">
            <el-select v-model="ruleForm.value16" placeholder="请选择">
                <el-option v-for='item in options3' :value='item.value' @click.native='getCateId(item, 3)'></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="width_50">
          <el-form-item label="投资方式" label-width="150px">
            <el-select v-model="ruleForm.value17" placeholder="请选择">
                <el-option v-for='item in options4' :value='item.value' @click.native='getCateId(item, 4)'></el-option>
            </el-select>
          </el-form-item>
        </div> -->
        <div class="width">
          <el-form-item label="投资需求说明" prop="value18" required label-width="150px">
            <el-input type="textarea" v-model="ruleForm.value18" :autosize="{ minRows: 5, maxRows: 99}" placeholder='请输入需求说明'></el-input>
          </el-form-item>
        </div>
        
        <div class="flex center width">
            <div class="width_50">
                <div class="inform_font left">机构商标</div>
                <div class="mat_30 left">
                    <el-upload
                          ref='uploadfiles1'
                          list-type="picture-card"
                          :file-list="uploadList1"
                          class='avatar-uploader'
                          action="#"
                          accept=".jpg,.jpeg,.png"
                          :multiple="true"
                          :limit="1"
                          name="file1"
                          :auto-upload="false"
                          :on-change='changeUpload1'
                          :on-exceed='handleExceed'
                          :on-remove="handleRemove1">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
            </div>
            <div class="width_50">
              <div class="inform_font left">营业执照</div>
                <div class="mat_30 left">
                    <el-upload
                          ref='uploadfiles2'
                          list-type="picture-card"
                          :file-list="uploadList2"
                          class='avatar-uploader'
                          action="#"
                          accept=".jpg,.jpeg,.png"
                          :multiple="true"
                          :limit="1"
                          name="file2"
                          :auto-upload="false"
                          :on-change='changeUpload2'
                          :on-exceed='handleExceed'
                          :on-remove="handleRemove2"
                          >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
            </div>
        </div>

        <el-dialog title="图片剪裁" :visible.sync="dialogVisible" append-to-body :close-on-click-modal='false' :before-close='cancleFinish'>
            <div class="cropper-content">
              <div class="cropper" style="text-align:center">
              <vueCropper
                  ref="cropper"
                  :img="option.img"
                  :outputSize="option.size"
                  :outputType="option.outputType"
                  :info="true"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :centerBox="option.centerBox"
                  :infoTrue="option.infoTrue"
                  :fixedBox="option.fixedBox"
                ></vueCropper>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="cancleFinish">取 消</el-button>
              <el-button type="primary" @click="finish('blob')">确认</el-button>
            </div>
        </el-dialog>
        
        <div class="width" style="margin-top: 20px;">
          <el-form-item label="机构介绍" prop="value19" required label-width="150px">
            <el-input type="textarea" v-model="ruleForm.value19" :autosize="{ minRows: 5, maxRows: 99}" placeholder='请输入机构介绍'></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <div class="width flex mat_30" style='margin-left: 75px; display: flex; align-items: center;'>
         <el-button :type="is_adopt == 0 ? 'warning' : is_adopt == 1 ? 'primary' : is_adopt == 2 ? 'danger' : 'primary'" @click='$throttle(submitForm)' :disabled="is_adopt == 0 || is_adopt == 1 ? true : false">{{is_adopt == 0 ? '审核中' : is_adopt == 1 ? '已通过' : is_adopt == 2 ? '未通过，重新提交' : '提交审核'}}</el-button>
         <span v-if="is_adopt == 2" style='color: red; font-size: 15px; margin-left: 12px;'>(不通过原因：{{no_adopt}})</span>
    </div>
  </div>
</template>

<script>
  import { Loading } from 'element-ui'
export default {
  data() {
    return {
      flagPic: -1,
      fileName: '',
      loadElemnt: '',
      // 裁剪组件的基础配置option
      option: {
          img: '', // 裁剪图片的地址
          info: true, // 裁剪框的大小信息
          outputSize: 0.8, // 裁剪生成图片的质量
          outputType: 'jpeg', // 裁剪生成图片的格式
          canScale: false, // 图片是否允许滚轮缩放
          autoCrop: true, // 是否默认生成截图框
          // autoCropWidth: 300, // 默认生成截图框宽度
          // autoCropHeight: 200, // 默认生成截图框高度
          fixedBox: true, // 固定截图框大小 不允许改变
          fixed: true, // 是否开启截图框宽高固定比例
          fixedNumber: [5, 2], // 截图框的宽高比例
          full: true, // 是否输出原图比例的截图
          canMoveBox: true, // 截图框能否拖动
          original: false, // 上传图片按照原始比例渲染
          centerBox: true, // 截图框是否被限制在图片里面
          infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      no_adopt: '',
      dialogVisible: false,
      is_adopt: -1,    //0 审核中   1  通过   2 未通过
      place_font: '请输入',
      cityList: [],
      cityListProps: {
           value: "id", //匹配响应数据中的id
           label: "name", //匹配响应数据中的name
           children: "chilren", //匹配响应数据中的children
      },
      uploadList1: [],
      uploadList2: [],
      ruleForm: {
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        value6: '',
        value7: '',
        value8: '',
        value9: '',
        value10: '',
        value11: '',
        value12: '',
        value13: '',
        value14: '',
        value15: '',
        value16: '',
        value17: '',
        value18: '',
        value19: '',
        value20: ''
      },
      options: [],
      options1: [],
      options2: [],
      options3: [
        {
           value: '债权融资',
           id: 1
        },
        {
           value: '股权融资',
           id: 2
        }
      ],
      options4: [
        {
           value: '债权融资',
           id: 1
        },
        {
           value: '股权融资',
           id: 2
        }
      ],
      rules: {
        value1: [
          { required: true, message: '企业名称不能为空', trigger: 'blur'},
          { required: true, message: '企业名称不能为空', trigger: 'change'}
        ],
        value2: [
          { required: true, message: '统一社会信用代码不能为空', trigger: 'blur'},
          { required: true, message: '统一社会信用代码不能为空', trigger: 'change'}
        ],
        value3: [
          { required: true, message: '机构码不能为空', trigger: 'blur'},
          { required: true, message: '机构码不能为空', trigger: 'change'}
        ],
        value4: [
          { required: true, message: '法定代表人不能为空', trigger: 'blur'},
          { required: true, message: '法定代表人不能为空', trigger: 'change'}
        ],
        value5: [
          { required: true, message: '平台负责人不能为空', trigger: 'blur'},
          { required: true, message: '平台负责人不能为空', trigger: 'change'}
        ],
        value6: [
          { required: true, message: '负责人联系电话不能为空', trigger: 'blur'},
          { required: true, message: '负责人联系电话不能为空', trigger: 'change'}
        ],
        value7: [
          { required: true, message: '电子邮箱不能为空', trigger: 'blur'},
          { required: true, message: '电子邮箱不能为空', trigger: 'change'}
        ],
        value8: [
          { required: true, message: '注册日期不能为空', trigger: 'blur'},
          { required: true, message: '注册日期不能为空', trigger: 'change'}
        ],
        value9: [
          { required: true, message: '基本规模不能为空', trigger: 'blur'},
          { required: true, message: '基本规模不能为空', trigger: 'change'}
        ],
        value10: [
          { required: true, message: '注册地址不能为空', trigger: 'blur'},
          { required: true, message: '注册地址不能为空', trigger: 'change'}
        ],
        value11: [
          { required: true, message: '通讯地址不能为空', trigger: 'blur'},
          { required: true, message: '通讯地址不能为空', trigger: 'change'}
        ],
        value12: [
          { required: true, message: '注册详细地址不能为空', trigger: 'blur'},
          { required: true, message: '注册详细地址不能为空', trigger: 'change'}
        ],
        value13: [
          { required: true, message: '通讯详细地址不能为空', trigger: 'blur'},
          { required: true, message: '通讯详细地址不能为空', trigger: 'change'}
        ],
        value15: [
          { required: true, message: '机构性质不能为空', trigger: 'blur'},
          { required: true, message: '机构性质不能为空', trigger: 'change'}
        ],
        value18: [
          { required: true, message: '投资需求说明不能为空', trigger: 'blur'},
          { required: true, message: '投资需求说明不能为空', trigger: 'change'}
        ],
        value19: [
          { required: true, message: '机构介绍不能为空', trigger: 'blur'},
          { required: true, message: '机构介绍不能为空', trigger: 'change'}
        ],
        value20: [
          { required: true, message: '联系人微信号不能为空', trigger: 'blur'},
          { required: true, message: '联系人微信号不能为空', trigger: 'change'}
        ],
      },
      people_num: '',
      type_id: '',
      nature_id: '',
      inv_mode: '',
      finance_trade: '',
      finance_img: '',
      addressId1: '',
      addressId2: '',
      loadingInstance: '',
      flaga: 0
    }
  },
  methods: {
    getFinancetype() {
      this.$post('/finance_type', {}).then(res => {
        if(res.data.status == 1) {
          this.options2 = res.data.result

          this.getData()
        }
      })
    },
    // 点击裁剪，这一步是可以拿到处理后的地址
    finish(type) {
      this.loadElemnt = Loading.service({
        fullscreen: true,
        text: '正在裁剪，请稍等...'
      })

        console.log('finish',type)
        let _this = this;
        let formData = new FormData()
        // 输出 
        if (type === 'blob') { 
          this.$refs.cropper.getCropBlob((data) => { 
   
            formData.append("file", data, this.fileName)
        
            if(this.flagPic == 1) {
              this.httpreq1(formData)
            }

            if(this.flagPic == 2) {
              this.httpreq2(formData)
            }
          }) 
        }
    },
    cancleFinish(type) {
       console.log(4564, this.flagPic)
       this.dialogVisible = false
       this.option.img = ''
       this.fileName = ''

       if(this.flagPic == 1) {
          this.uploadList1 = []
          this.finance_trade = ''
       }

       if(this.flagPic == 2) {
          this.uploadList2 = []
          this.finance_img = ''
       }


       // let formData = new FormData()
       // formData.append('file', this.httpreq2File)

       // this.httpreq2(formData)
    },
    handleBeforeUpload(file) {
       let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1)
       const extension = testmsg === "jpg"
       const extension1 = testmsg === "jpeg"
       const extension2 = testmsg === "png"
       
         if (!extension && !extension1 && !extension2) {
          this.$message({
             message: "上传文件只能是.jpg、.jpeg、.png格式!",
             type: "warning",
           duration: 1500
          })

          this.option.img = ''
          return false
         }
    },
    handleExceed() {
        this.$message({
          message: '最大上传数量为1张!',
          type: 'warning',
          duration: 1500
        })
    },
    handleRemove1() {
      this.uploadList1 = []
      this.finance_trade = ''
    },
    handleRemove2() {
      this.uploadList2 = []
      this.finance_img = ''
    },
    changeUpload1(file, fileList) {
       let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1)
       const extension = testmsg === "jpg"
       const extension1 = testmsg === "jpeg"
       const extension2 = testmsg === "png"

       if (!extension && !extension1 && !extension2) {
            this.$message({
               message: "上传文件只能是.jpg、.jpeg、.png格式!",
               type: "warning",
               duration: 1500
            })
            this.option.img = ''

            this.finance_trade = ''

            this.uploadList1 = []
            return false
       }

       let url = URL.createObjectURL(file.raw)

       
       // 上传成功后将图片地址赋值给裁剪框显示图片
       this.$nextTick(() => {
          this.option.img = url
          this.fileName = file.name
         
          this.flagPic = 1
          this.dialogVisible = true
       }) 
    },
    changeUpload2(file, fileList) {
       let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1)
       const extension = testmsg === "jpg"
       const extension1 = testmsg === "jpeg"
       const extension2 = testmsg === "png"

       if (!extension && !extension1 && !extension2) {
            this.$message({
               message: "上传文件只能是.jpg、.jpeg、.png格式!",
               type: "warning",
               duration: 1500
            })
            this.option.img = ''

            this.finance_img = ''

            this.uploadList2 = []
            return false
       }

       let url = URL.createObjectURL(file.raw)

       
       // 上传成功后将图片地址赋值给裁剪框显示图片
       this.$nextTick(() => {
          this.option.img = url
          this.fileName = file.name
          
          this.flagPic = 2
          this.dialogVisible = true
       }) 
    },
    httpreq1(formData) {
      this.$post('upload_file', formData).then(res => {
         this.finance_trade = res.data.address
         this.dialogVisible = false

         let picObj = {url: this.finance_trade}
         this.uploadList1.push(picObj)

         this.loadElemnt.close()
      })
    },
    httpreq2(formData) {
      this.$post('upload_file', formData).then(res => {
         this.finance_img = res.data.address
         this.dialogVisible = false

         let picObj = {url: this.finance_img}
         this.uploadList2.push(picObj)

         this.loadElemnt.close()
      })
    },
    // 获得id
    getCateId(item, type) {
      if(type == 1) {
         this.people_num = item.id
      }

      if(type == 2) {
         this.type_id = item.id
      }

      if(type == 3) {
         this.nature_id = item.id
      }

      if(type == 4) {
         this.inv_mode = item.id
      }
    },
    changeAddress1() {},
    changeAddress2() {},
    // 适用地区
    getQhCityList() {
      let data = {}

      this.$post('/qh_city_lst', data).then(res => {
        this.options = res.data.result ? res.data.result : []

        console.log('this.flaga', this.flaga)
        if(this.flaga == 1) {
          res.data.result.map(item => {
             if(item.id == this.addressId1) {
               this.ruleForm.value10 = item.name
               console.log(123)
             }

             if(item.id == this.addressId2) {
               this.ruleForm.value11 = item.name
             }

              console.log(item.id)
          })

          console.log(this.addressId1, this.addressId2,res.data.result,8979)
        }
      })
    },
    clearClass(type) {
      if(type == 1) {
         this.addressId1 = ''
      }

      if(type == 2) {
         this.addressId2 = ''
      }
    },
    setAddressId(id, type) {
      if(type == 1) {
         this.addressId1 = id
      }

      if(type == 2) {
         this.addressId2 = id
      }
    },
    // 机构信息更新
    submitForm() {
      let title = this.is_adopt == -1 ? '是否提交审核' : this.is_adopt == 2 ? '是否重新提交?' : ''
      
      this.$confirm(title, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
        }).then(() => {
              this.$refs['ruleForm'].validate((valid) => {
              if (valid) {
                let data = {
                  token: localStorage.eleToken ? localStorage.eleToken : '',
                  name: this.ruleForm.value1,
                  credit_code: this.ruleForm.value2,
                  finance_code: this.ruleForm.value3,
                  legal_name: this.ruleForm.value4,
                  mobile: this.ruleForm.value6,
                  finance_user: this.ruleForm.value5,
                  email: this.ruleForm.value7,
                  people_num: this.people_num,
                  register_time: this.$turnTime(this.ruleForm.value8),
                  register_address: [3126, this.addressId1],
                  com_address: [3126, this.addressId2],
                  registers_address: this.ruleForm.value12,
                  coms_address: this.ruleForm.value13,
                  type_id: this.type_id,
                  nature_id: this.nature_id,
                  inv_mode: this.inv_mode,
                  inv_text: this.ruleForm.value18,
                  finance_trade: this.finance_trade,
                  finance_img: this.finance_img,
                  introduce: this.ruleForm.value19,
                  we_chat: this.ruleForm.value20
                }

                this.$post('/info_save', data).then(res => {
                  let type = res.data.status == 1 ? 'success' : 'error'

                  this.$message({
                     message: res.data.msg,
                     type,
                     duration: 2000
                  })

                  if(res.data.status == 1) {
                    this.$router.go(0)
                  }

                })
              } else {
                return false
              }
            })
        }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              })        
        })
      console.log(this.ruleForm)
    },
    // 信息获取
    getData() {
      let data = {token: localStorage.eleToken ? localStorage.eleToken : ''}

      this.$post('/financeInfo', data).then(res => {
         if(res.data.result) {
            this.flaga = 1
            this.loadingInstance = Loading.service({
              fullscreen: true,
              text: '正在加载'
            })

            this.ruleForm.value1 = res.data.result.name
            this.ruleForm.value2 = res.data.result.credit_code
            this.ruleForm.value3 = res.data.result.finance_code
            this.ruleForm.value4 = res.data.result.legal_name
            this.ruleForm.value5 = res.data.result.finance_user
            this.ruleForm.value6 = res.data.result.mobile
            this.ruleForm.value7 = res.data.result.email
            this.ruleForm.value8 = res.data.result.register_time
            this.ruleForm.value20 = res.data.result.we_chat
            this.ruleForm.value14 = res.data.result.product_num

            this.people_num = res.data.result.people_num
            // this.ruleForm.value9 = this.options1[res.data.result.people_num - 1].value


            this.addressId1 = res.data.result.register_address
            this.addressId2 = res.data.result.com_address

            this.ruleForm.value12 = res.data.result.registers_address
            this.ruleForm.value13 = res.data.result.coms_address

            this.type_id = res.data.result.type_id
            this.ruleForm.value15 = this.options2[res.data.result.type_id - 1].name

            this.nature_id = res.data.result.nature_id
            // this.ruleForm.value16 = this.options3[res.data.result.nature_id - 1].value

            this.inv_mode = res.data.result.inv_mode
            // this.ruleForm.value17 = this.options4[res.data.result.inv_mode - 1].value

            let picObj1 = {url: res.data.result.finance_trade}
            this.uploadList1.push(picObj1)

            this.finance_trade = res.data.result.finance_trade

            let picObj2 = {url: res.data.result.finance_img}
            this.uploadList2.push(picObj2)

            this.finance_img = res.data.result.finance_img

            this.ruleForm.value18 = res.data.result.inv_text

            this.ruleForm.value19 = res.data.result.introduce

            this.place_font = res.data.result.product_num ? res.data.result.product_num : '请输入'

            this.is_adopt = res.data.result.is_adopt

            this.no_adopt = res.data.result.no_adopt ? res.data.result.no_adopt : ''

            this.getQhCityList()

            setTimeout(() => {
              this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                 this.loadingInstance.close()
              })
            }, 500)
         }else {
            this.getQhCityList()
         }
      })
    }
  },
  mounted() {
    this.getFinancetype()
  },
  destoryed() {
    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
       this.loadingInstance.close()
    })
  }
}
</script>

<style scoped>
/deep/.el-form--label-left .el-form-item__label {
    text-align: right;
}

.el-textarea__inner{
  word-break: break-all;
}
.inform_font{
  font-size: 15px;
  color: #606266;
}
.inform_font:before{
  content: '*';
  color: #F56C6C;
  margin-right: 3px;
}
.left {
  text-align: left;
  margin-left: 83px;
}


.cropper-content .cropper{
    width: auto;
    height: 300px;
}
</style>